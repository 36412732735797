:root {
  --theme-primary-color: #ff0000;
}
:root .dark-mode {
  --theme-primary-color: #ff0000;
}
ol {
}
ol li {
  list-style-type: decimal !important;
}
ul li {
  list-style-type: square !important;
}
pre code,
code {
  background-color: #e9e9e9;
}
code {
  padding: 4px 8px;
  margin: 0 2px;
}
body.dark-theme pre code {
  background-color: #222;
}
body.dark-theme code {
  background-color: #222;
}
strong {
  font-weight: bold;
}
body {
  background-color: #f6f6f6;
}
aside {
  background-color: #f6f6f6 !important;
}
body.dark-theme {
  background-color: #141414;
}
body.dark-theme aside {
  background-color: #141414 !important;
}
.screenshots {
  padding: 10px 0;
  margin: 10px 0 20px 0;
}
.screenshots img {
  border: 1px solid #ccc;
  display: inline-block;
  width: 800px;
  height: auto;
}
.screenshots-summary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.screenshots-summary img {
  width: 45%;
  margin: 0 10px 10px 0;
}
body.dark-theme .screenshots img {
  border-color: #333;
}
.screenshots figcaption {
  text-align: left;
  font-style: italic;
  padding: 5px;
  margin: 0 0 20px 0;
}
